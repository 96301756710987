import request from '@/utils/request';
const baseUrl = process.env.VUE_APP_API_RULE;
const BASE_Mul_URL = process.env.VUE_APP_LABEL;
import qs from 'qs';
// 自动规则-创建规则
export function rulesCreate(data) {
  return request({
    baseURL: baseUrl,
    url: '/alert/rules/create',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 自动规则-列表
export function rules(params) {
  let ret = '';
  for (let it in params) {
    ret += encodeURIComponent(it) + '=' + encodeURIComponent(params[it]) + '&';
  }

  return request({
    baseURL: baseUrl,
    url: '/alert/rules?' + ret,
    method: 'get',
    timeout:30000
  });
}
//规则详情
export function rulesDetail(params) {
  return request({
    baseURL: baseUrl,
    url: '/alert/rules/detail',
    method: 'get',
    params,
  });
}
// 自动规则-修改规则
export function rulesUpdate(data) {
  return request({
    baseURL: baseUrl,
    url: '/alert/rules/update',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 自动规则-删除规则
export function rulesDelete(data) {
  return request({
    baseURL: baseUrl,
    url: `/alert/rules/delete?rule_id=${data}`,
    method: 'post',
    transformRequest: [
      function (data) {
        return qs.stringify(data);
      },
    ],
  });
}
// 自动规则-创建执行
export function rulesRun(data) {
  return request({
    baseURL: baseUrl,
    url: `/alert/rules/run?rule_id=${data}`,
    method: 'post',
    headers: { 'Content-Type': 'form-data' },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
//自动规则修改状态
export function updateStatus(data) {
  let ret = '';
  for (let it in data) {
    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
  }
  return request({
    baseURL: baseUrl,
    url: '/alert/rules/updateStatus?' + ret,
    method: 'post',
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 自动规则-执行-列表
export function runList(params) {
  return request({
    baseURL: baseUrl,
    url: '/alert/rules/run/list',
    method: 'get',
    params,
  });
}
//自动规则-模板列表
export function rulesTemplate(params) {
  return request({
    baseURL: baseUrl,
    url: '/alert/rules/template',
    method: 'get',
    params,
  });
}
// 自动规则-团队列表
export function gimpAreaList() {
  return request({
    baseURL: baseUrl,
    url: '/gimp/areaList',
    method: 'get',
  });
}
// 自动规则-国家列表
export function gimpCountryList() {
  return request({
    baseURL: baseUrl,
    url: '/gimp/countryList',
    method: 'get',
  });
}
// 自动规则-币种列表
export function gimpCurrencyList() {
  return request({
    baseURL: baseUrl,
    url: '/gimp/currencyList',
    method: 'get',
  });
}
//历史预警消息
export function messages(params) {
  return request({
    baseURL: baseUrl,
    url: '/alert/messages',
    method: 'get',
    params,
  });
}
//历史预警消息统计
export function messagesSummary(params) {
  return request({
    baseURL: baseUrl,
    url: '/alert/messages/summary',
    method: 'get',
    params,
  });
}

export function getCampaignList(params) {
  return request({
    baseURL: BASE_Mul_URL,
    url: '/saleCampaign/getCampaignList',
    method: 'get',
    params,
  });
}